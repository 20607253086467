import { useEffect, useRef, useCallback, useState } from 'react';
import { PDFDocument, StandardFonts } from 'pdf-lib'

import { RealtimeClient } from '@openai/realtime-api-beta';
import { ItemType } from '@openai/realtime-api-beta/dist/lib/client.js';
import { WavRecorder, WavStreamPlayer } from '../lib/wavtools/index.js';
import { instructions } from '../utils/conversation_config.js';
import { WavRenderer } from '../utils/wav_renderer';

import { X, Play, Mic, MicOff } from 'react-feather';
import { Button } from '../components/button/Button';
import { Brief, generateBrief } from '../utils/generateBrief';
import { resultGeneratedBrief } from '../utils/mocks/mocks';
import ofertas from '../data/ofertas-mercado.json';
import './ConsolePage.scss';
import useClock from '../hooks/useClock';
import Modal from '../components/modal/Modal';


/**
 * Type for all event logs
 */
interface RealtimeEvent {
  time: string;
  source: 'client' | 'server';
  count?: number;
  event: { [key: string]: any };
}

interface Ofertas {
  ESTRATEGIA: string;
  OFERTA: string;
  DESCUENTO: string;
  PRECIO_REGULAR: string;
  AHORRO: string;
  VIGENCIA: string;
}

// Type for voice model
enum Voices {
  Alloy = "alloy",
  Shimmer = "shimmer",
  Echo = "echo"
};

export function ConsolePage() {
  const [showModal, setShowModal] = useState(false);
  /**
   * Instantiate:
   * - WavRecorder (speech input)
   * - WavStreamPlayer (speech output)
   * - RealtimeClient (API client)
   */
  const wavRecorderRef = useRef<WavRecorder>(
    new WavRecorder({ sampleRate: 24000 })
  );
  const wavStreamPlayerRef = useRef<WavStreamPlayer>(
    new WavStreamPlayer({ sampleRate: 24000 })
  );
  const clientRef = useRef<RealtimeClient>(
    new RealtimeClient({
      apiKey: process.env.REACT_APP_OPENAI_API_KEY,
      dangerouslyAllowAPIKeyInBrowser: true,
    })
  );

  /**
   * References for
   * - Rendering audio visualization (canvas)
   * - Autoscrolling event logs
   * - Timing delta for event log displays
   */
  const clientCanvasRef = useRef<HTMLCanvasElement>(null);
  const serverCanvasRef = useRef<HTMLCanvasElement>(null);
  const eventsScrollHeightRef = useRef(0);
  const eventsScrollRef = useRef<HTMLDivElement>(null);
  const startTimeRef = useRef<string>(new Date().toISOString());

  /**
   * All of our variables for displaying application state
   * - items are all conversation items (dialog)
   * - realtimeEvents are event logs, which can be expanded
   * - memoryKv is for set_memory() function
   * - coords, marker are for get_weather() function
   */
  const [items, setItems] = useState<ItemType[]>([]);
  const [realtimeEvents, setRealtimeEvents] = useState<RealtimeEvent[]>([]);
  const [expandedEvents, setExpandedEvents] = useState<{
    [key: string]: boolean;
  }>({});
  const [isConnected, setIsConnected] = useState(false);
  const [canPushToTalk, setCanPushToTalk] = useState(true);
  const [isRecording, setIsRecording] = useState(false);
  const [memoryKv, setMemoryKv] = useState<{ [key: string]: any }>({});

  const clock = useClock();

  /**
   * Utility for formatting the timing of logs
   */
  const formatTime = useCallback((timestamp: string) => {
    const startTime = startTimeRef.current;
    const t0 = new Date(startTime).valueOf();
    const t1 = new Date(timestamp).valueOf();
    const delta = t1 - t0;
    const hs = Math.floor(delta / 10) % 100;
    const s = Math.floor(delta / 1000) % 60;
    const m = Math.floor(delta / 60_000) % 60;
    const pad = (n: number) => {
      let s = n + '';
      while (s.length < 2) {
        s = '0' + s;
      }
      return s;
    };
    return `${pad(m)}:${pad(s)}.${pad(hs)}`;
  }, []);

  /**
   * When you click the API key
   */
  const resetAPIKey = useCallback(() => {
    const apiKey = prompt('OpenAI API Key');
    if (apiKey !== null) {
      localStorage.clear();
      localStorage.setItem('tmp::voice_api_key', apiKey);
      window.location.reload();
    }
  }, []);

  /**
   * Connect to conversation:
   * WavRecorder taks speech input, WavStreamPlayer output, client is API client
   */
  const connectConversation = useCallback(async () => {
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;

    // Set state variables
    startTimeRef.current = new Date().toISOString();
    setIsConnected(true);
    setRealtimeEvents([]);
    setItems(client.conversation.getItems());

    // Connect to microphone
    await wavRecorder.begin();

    // Connect to audio output
    await wavStreamPlayer.connect();

    // Connect to realtime API
    await client.connect();
    client.sendUserMessageContent([
      {
        type: `input_text`,
        text: `Hola, ¿quien eres?`,
      },
    ]);

    if (client.getTurnDetectionType() === 'server_vad') {
      await wavRecorder.record((data) => client.appendInputAudio(data.mono));
    }
  }, []);

  /**
   * Disconnect and reset conversation state
   */
  const disconnectConversation = useCallback(async () => {
    setIsConnected(false);
    setRealtimeEvents([]);
    setItems([]);
    setMemoryKv({});

    const client = clientRef.current;
    client.disconnect();

    const wavRecorder = wavRecorderRef.current;
    await wavRecorder.end();

    const wavStreamPlayer = wavStreamPlayerRef.current;
    await wavStreamPlayer.interrupt();
  }, []);

  const deleteConversationItem = useCallback(async (id: string) => {
    const client = clientRef.current;
    client.deleteItem(id);
  }, []);

  /**
   * In push-to-talk mode, start recording
   * .appendInputAudio() for each sample
   */
  const startRecording = async () => {
    setIsRecording(true);
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const trackSampleOffset = await wavStreamPlayer.interrupt();
    if (trackSampleOffset?.trackId) {
      const { trackId, offset } = trackSampleOffset;
      await client.cancelResponse(trackId, offset);
    }
    await wavRecorder.record((data) => client.appendInputAudio(data.mono));
  };

  /**
   * In push-to-talk mode, stop recording
   */
  const stopRecording = async () => {
    setIsRecording(false);
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    await wavRecorder.pause();
    client.createResponse();
  };

  /**
   * Switch between Manual <> VAD mode for communication
   */
  const changeTurnEndType = async (value: string) => {
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    if (value === 'none' && wavRecorder.getStatus() === 'recording') {
      await wavRecorder.pause();
    }
    client.updateSession({
      turn_detection: value === 'none' ? null : { type: 'server_vad' },
    });
    if (value === 'server_vad' && client.isConnected()) {
      await wavRecorder.record((data) => client.appendInputAudio(data.mono));
    }
    setCanPushToTalk(value === 'none');
  };

  /**
   * Auto-scroll the event logs
   */
  useEffect(() => {
    if (eventsScrollRef.current) {
      const eventsEl = eventsScrollRef.current;
      const scrollHeight = eventsEl.scrollHeight;

      // Only scroll if height has just changed
      if (scrollHeight !== eventsScrollHeightRef.current) {
        eventsEl.scrollTop = scrollHeight;
        eventsScrollHeightRef.current = scrollHeight;
      }
    }
  }, [realtimeEvents]);

  /**
   * Auto-scroll the conversation logs
   */
  useEffect(() => {
    const conversationEls = [].slice.call(
      document.body.querySelectorAll('[data-conversation-content]')
    );
    for (const el of conversationEls) {
      const conversationEl = el as HTMLDivElement;
      conversationEl.scrollTop = conversationEl.scrollHeight;
    }
  }, [items]);

  /**
   * Set up render loops for the visualization canvas
   */
  useEffect(() => {
    let isLoaded = true;

    const wavRecorder = wavRecorderRef.current;
    const clientCanvas = clientCanvasRef.current;
    let clientCtx: CanvasRenderingContext2D | null = null;

    const wavStreamPlayer = wavStreamPlayerRef.current;
    const serverCanvas = serverCanvasRef.current;
    let serverCtx: CanvasRenderingContext2D | null = null;

    const render = () => {
      if (isLoaded) {
        if (clientCanvas) {
          if (!clientCanvas.width || !clientCanvas.height) {
            clientCanvas.width = clientCanvas.offsetWidth;
            clientCanvas.height = clientCanvas.offsetHeight;
          }
          clientCtx = clientCtx || clientCanvas.getContext('2d');
          if (clientCtx) {
            clientCtx.clearRect(0, 0, clientCanvas.width, clientCanvas.height);
            const result = wavRecorder.recording
              ? wavRecorder.getFrequencies('voice')
              : { values: new Float32Array([0]) };
            WavRenderer.drawBars(
              clientCanvas,
              clientCtx,
              result.values,
              '#0099ff',
              10,
              0,
              8
            );
          }
        }
        if (serverCanvas) {
          if (!serverCanvas.width || !serverCanvas.height) {
            serverCanvas.width = serverCanvas.offsetWidth;
            serverCanvas.height = serverCanvas.offsetHeight;
          }
          serverCtx = serverCtx || serverCanvas.getContext('2d');
          if (serverCtx) {
            serverCtx.clearRect(0, 0, serverCanvas.width, serverCanvas.height);
            const result = wavStreamPlayer.analyser
              ? wavStreamPlayer.getFrequencies('voice')
              : { values: new Float32Array([0]) };
            WavRenderer.drawBars(
              serverCanvas,
              serverCtx,
              result.values,
              '#009900',
              10,
              0,
              8
            );
          }
        }
        window.requestAnimationFrame(render);
      }
    };
    render();

    return () => {
      isLoaded = false;
    };
  }, []);

  /**
   * Core RealtimeClient and audio capture setup
   * Set all of our instructions, tools, events and more
   */
  useEffect(() => {
    // Get refs
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const client = clientRef.current;

    // Set instructions
    client.updateSession({ instructions: instructions });
    // Set transcription, otherwise we don't get user transcriptions back
    client.updateSession({ input_audio_transcription: { model: 'whisper-1' } });

    client.updateSession({ voice: Voices.Echo });

    // Add tools
    client.addTool(
      {
        name: 'get_offers',
        description: 'En caso de que el usuario mencione estrategias o vigencias, usa esta herramienta para obtener las ofertas con vigencia.',
        parameters: {
          type: 'object',
          properties: {
            estrategia: {
              type: 'string',
              description: 'La estrategia de la oferta, por ejemplo, "Canasta del ahorro".'
            },
            vigencia: {
              type: 'string',
              description: 'La vigencia de la oferta, como "18 AL 21 OCT".'
            },
          },
        },
      }, async ({ estrategia, vigencia }: {
        estrategia: string,
        vigencia: string
      }) => {
      console.log("get_offers", estrategia, vigencia);

      // Filtra las ofertas en función de los parámetros
      const ofertasFiltradas = ofertas.filter(oferta =>
        (estrategia ? oferta.ESTRATEGIA === estrategia : true) &&
        (vigencia ? oferta["VIGENCIA"] === vigencia : true)
      );
      return ofertasFiltradas;
    });
    client.addTool({
      name: 'show_brief',
      description: 'Muestra el brief generado cuando el usuario lo solicite o acepte tu recomendación de mostrarlo.',
      parameters: {}
    }, async () => {
      setShowModal(true);
      return 'Este es el brief de la campaña';
    });

    // handle realtime events from client + server for event logging
    client.on('realtime.event', (realtimeEvent: RealtimeEvent) => {
      setRealtimeEvents((realtimeEvents) => {
        const lastEvent = realtimeEvents[realtimeEvents.length - 1];
        if (lastEvent?.event.type === realtimeEvent.event.type) {
          // if we receive multiple events in a row, aggregate them for display purposes
          lastEvent.count = (lastEvent.count || 0) + 1;
          return realtimeEvents.slice(0, -1).concat(lastEvent);
        } else {
          return realtimeEvents.concat(realtimeEvent);
        }
      });
    });
    client.on('error', (event: any) => console.error(event));
    client.on('conversation.interrupted', async () => {
      const trackSampleOffset = await wavStreamPlayer.interrupt();
      if (trackSampleOffset?.trackId) {
        const { trackId, offset } = trackSampleOffset;
        await client.cancelResponse(trackId, offset);
      }
    });
    client.on('conversation.updated', async ({ item, delta }: any) => {
      const items = client.conversation.getItems();
      if (delta?.audio) {
        wavStreamPlayer.add16BitPCM(delta.audio, item.id);
      }
      if (item.status === 'completed' && item.formatted.audio?.length) {
        const wavFile = await WavRecorder.decode(
          item.formatted.audio,
          24000,
          24000
        );
        item.formatted.file = wavFile;
      }
      setItems(items);
    });

    setItems(client.conversation.getItems());

    return () => {
      // cleanup; resets to defaults
      client.reset();
    };
  }, []);


  const generatePDF = async (brief: Brief) => {
    // Create a new PDFDocument
    const pdfDoc = await PDFDocument.create();

    // Add a blank page to the document
    const page = pdfDoc.addPage();

    // Get the width and height of the page
    const { width, height } = page.getSize();

    // Set initial yOffset and margin
    let yOffset = height - 30;
    const margin = 50;
    const lineHeight = 15;
    const maxLineWidth = width - 2 * margin;

    // Load a font
    const font = await pdfDoc.embedFont(StandardFonts.TimesRoman)

    // Function to draw text with line wrapping
    const drawTextWithWrapping = (text: string, x: number, y: number, size: number) => {
      const words = text.split(' ');
      let line = '';
      for (const word of words) {
        const testLine = line + word + ' ';
        const testLineWidth = font.widthOfTextAtSize(testLine, size);
        if (testLineWidth > maxLineWidth) {
          page.drawText(line, { x, y, size, font });
          line = word + ' ';
          y -= size + 5;
        } else {
          line = testLine;
        }
      }
      page.drawText(line, { x, y, size, font });
      return y - size - 5;
    };

    // Print nombre de campaña
    yOffset = drawTextWithWrapping('Nombre de la campaña', margin, yOffset, 13);
    yOffset = drawTextWithWrapping(brief.nombreCampanaProyecto, margin, yOffset, 12);

    // Print cliente
    yOffset = drawTextWithWrapping('Cliente', margin, yOffset, 13);
    yOffset = drawTextWithWrapping(brief.cliente, margin, yOffset, 12);

    // Print negocio marca
    yOffset = drawTextWithWrapping('Negocio/Marca', margin, yOffset, 13);
    yOffset = drawTextWithWrapping(brief.negocioMarca, margin, yOffset, 12);

    // Print objetivos kpis
    yOffset = drawTextWithWrapping('Objetivos y KPIs', margin, yOffset, 13);
    yOffset = drawTextWithWrapping(brief.objetivosKPIs.dolorDesafio, margin, yOffset, 12);

    yOffset = drawTextWithWrapping('Objetivo', margin, yOffset, 13);
    yOffset = drawTextWithWrapping(brief.objetivosKPIs.objetivo, margin, yOffset, 12);

    yOffset = drawTextWithWrapping('Desafío', margin, yOffset, 13);
    yOffset = drawTextWithWrapping(brief.objetivosKPIs.desafio, margin, yOffset, 12);

    yOffset = drawTextWithWrapping('KPIs', margin, yOffset, 13);
    brief.objetivosKPIs.kpis.forEach((kpi, i) => {
      yOffset = drawTextWithWrapping(`${i + 1}. ${kpi}`, margin, yOffset, 12);
    });

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytes = await pdfDoc.save();

    // Download the pdf with the name "brief.pdf"
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'brief.pdf';
    link.click();
  };

  // Trigger the brief generation
  const handleBrief = async () => {
    try {
      const client = clientRef.current;
      const history = client.conversation.getItems();

      const conversations = history.map(item => {
        return {
          role: item.role,
          text: item.formatted.text,
          transcript: item.formatted.transcript
        }
      });

      const result = await generateBrief(conversations.map(item => item.transcript).join(''));
      // TODO: handle result
    } catch (error) {
      // TODO: handle error
      console.error(error);
    }
  };

  /**
   * Render the application
   */
  return (
    <div data-component="ConsolePage">

      {/* pdf modal */}
      <Modal isOpen={showModal} onClose={() => setShowModal(false)} />
      {/* pdf modal */}

      <div className="content-main">
        <div className="content-logs">
          <div className="content-block conversation">

            <div className={`iphone-x ${!isConnected ? 'unlocked' : ''}`}>
              <div className="unlock-bg"></div>

              <i>Speaker</i>
              <b>Camera</b>
              {!isConnected && <s className="timeDisplay">{clock}</s>}
              {/* chat body */}

              <div className="content-block-body " data-conversation-content>

                {items.length > 0 && <article className="conversation-header">
                  <div className="content-block-label">
                    <p>NUEVO BRIEF</p>
                  </div>
                  <div className="content-block-title">Conversación</div>
                </article>}
                {/* {!items.length && <div style={{ padding: '20px' }}>Esperando conexión...</div>} */}
                {items.map((conversationItem, i) => {
                  return (
                    <div className="conversation-item" key={conversationItem.id}>
                      <div className={`speaker ${conversationItem.role || ''}`}>
                        <div className='speaker-icon'
                        >
                          {conversationItem.role === 'user' ? (
                            <img src="/user.svg" alt="iphone-x" />
                          ) : (
                            <img src="/bot.svg" alt="iphone-x" />
                          )}
                        </div>
                        <div
                          className="close"
                          onClick={() =>
                            deleteConversationItem(conversationItem.id)
                          }
                        >
                          <X />
                        </div>
                      </div>
                      <div className={`speaker-content`}>
                        {/* tool response */}
                        {conversationItem.type === 'function_call_output' && (
                          <div>{conversationItem.formatted.output}</div>
                        )}
                        {/* tool call */}
                        {!!conversationItem.formatted.tool && (
                          <div>
                            {conversationItem.formatted.tool.name}(
                            {conversationItem.formatted.tool.arguments})
                          </div>
                        )}
                        {!conversationItem.formatted.tool &&
                          conversationItem.role === 'user' && (
                            <div >
                              {conversationItem.formatted.transcript ||
                                (conversationItem.formatted.audio?.length
                                  ? '(awaiting transcript)'
                                  : conversationItem.formatted.text ||
                                  '(item sent)')}
                            </div>
                          )}
                        {!conversationItem.formatted.tool &&
                          conversationItem.role === 'assistant' && (
                            <div>
                              {conversationItem.formatted.transcript ||
                                conversationItem.formatted.text ||
                                '(truncated)'}
                            </div>
                          )}
                        {conversationItem.formatted.file && (
                          <audio
                            src={conversationItem.formatted.file.url}
                            controls
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* chat body */}
              {/* chat actions */}
              <div className="content-actions">
                {items.length > 0 && isConnected && canPushToTalk && (
                  <Button
                    label={isRecording ? 'Suelta para enviar' : 'Presiona para hablar'}
                    buttonStyle={isRecording ? 'alert' : 'regular'}
                    disabled={!isConnected || !canPushToTalk}
                    onMouseDown={startRecording}
                    onMouseUp={stopRecording}
                    onTouchStart={startRecording}
                    onTouchEnd={stopRecording}
                    iconPosition={isConnected ? 'start' : 'end'}
                    icon={isConnected ? Mic : Play}
                  />
                )}
                <Button
                  label={isConnected ? 'Terminar conversación' : 'Iniciar conversación'}
                  iconPosition={isConnected ? 'end' : 'end'}
                  icon={isConnected ? MicOff : Play}
                  buttonStyle={isConnected ? 'regular' : 'action'}
                  onClick={
                    isConnected ? disconnectConversation : connectConversation
                  }
                />

                {/* <button onClick={async () => await generatePDF(resultGeneratedBrief)}>Obtener conversación</button> */}
              </div>
              {/* chat actions */}

              {/* <span>Left action button</span>
              <span>Right action button</span> */}
            </div>

          </div>

        </div>
      </div>
    </div>
  );
}
