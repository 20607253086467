import { useState, useEffect } from 'react';

const useClock = () => {
    const [time, setTime] = useState('');

    useEffect(() => {
        // Función para actualizar la hora
        const updateClock = () => {
            const now = new Date();
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            setTime(`${hours}:${minutes}`);
        };

        // Llama a la función inmediatamente y luego cada segundo
        updateClock();
        const intervalId = setInterval(updateClock, 1000);

        // Limpia el intervalo al desmontar el componente
        return () => clearInterval(intervalId);
    }, []);

    return time;
};

export default useClock;
