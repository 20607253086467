import { X } from 'react-feather';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
};

const Modal = ({ isOpen, onClose }: ModalProps) => {
  if (!isOpen) return null;

  return (
    <section className="pdf-modal">
      <button className="pdf-modal-close" onClick={onClose}>
        <X />
      </button>
      <div className="pdf-modal-content">
        <div className="pdf-previsualizer">
          <img className='pdf-image' src="/imagen_preview.png" alt="imagen preview" />
        </div>
      </div>
    </section>
  );
};

export default Modal;