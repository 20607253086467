import { generateObject } from 'ai';
import { createOpenAI } from '@ai-sdk/openai';
import { z } from 'zod';
import { systemPromptGenerateBrief } from './prompts';

// Create model 
const google = createOpenAI({
  apiKey: process.env.OPENAI_API_KEY,
});

// Instance of the model
const model = google(`gpt-4o`);

// Define the schema of the object to generate
const zodSchema = z.object({
  nombreCampanaProyecto: z.string(),
  // trazabilidadParaOrdenarMedios: z.string().optional(),
  // responsableClienteAgenciaMedios: z.string().optional(),
  cliente: z.string(),
  negocioMarca: z.string(),
  // tipoCampana: z.string(),
  objetivosKPIs: z.object({
    dolorDesafio: z.string(),
    objetivo: z.string(),
    desafio: z.string(),
    kpis: z.string().array(),
  }),
  mensajeExpectativa: z.string(),
  diferencialRTB: z.string(),
  // dataAprendizajes: z.string(),
  audiencias: z.object({
    primario: z.string(),
    secundario: z.string(),
    cicloDeVidaCliente: z.string().optional(),
  }),
  queDebenSentirPensar: z.string(),
  pdvPop: z.string(),
  regional: z.object({
    requiereComunicacionRegional: z.boolean(),
    detalles: z.string().optional(),
  }),
  omnicanalidad: z.string().optional(),
  fechasCampana: z.object({
    fechaTotalCampana: z.string().optional(),
    fechaAireMedios: z.string().optional(),
    nacional: z.boolean().optional(),
    regional: z.boolean().optional(),
    detallesRegional: z
      .object({
        priorizacion: z.string().optional(),
        restricciones: z.string().optional(),
        competidores: z.string().optional(),
      })
      .optional(),
  }),
  presupuesto: z.object({
    medios: z.number().optional(),
    produccion: z.number().optional(),
    accionesOtros: z.number().optional(),
    sugerenciasCanalesFormatos: z.string().optional(),
    pptoMedio: z
      .object({
        disponible: z.boolean(),
        cantidad: z.number().optional(),
        incluyeIVA: z.boolean().optional(),
      })
      .optional(),
    distribucion: z
      .object({
        canalesDirectos: z.number().optional(),
        relacional: z.number().optional(),
      })
      .optional(),
  }),
  // susceptibleATesteo: z.enum(["SI", "NO", "PPTO$"]),
});

export type Brief = z.infer<typeof zodSchema>;

// Generate brief
export const generateBrief = async (history: string) => {
  // Generate the object
  const { object } = await generateObject({
    model: model,
    schema: zodSchema,
    prompt: `${systemPromptGenerateBrief} Este es el historial de la conversación: ${history}`,
  });

  return object;
  // TODO: Add more logic to handle the object generated by the AI

};
